<template>
  <div class="au-cont">
    <img class="demo" src="@/assets/img/authentication.png" alt="">
    <div class="au-name">身份验证</div>
    <div class="au-title">请输入预留手机{{phone.show}}中间四位号码</div>

    <SecurityCode v-model="value"></SecurityCode>

    <div class="btns">
      <cube-button class="btn" @click="goNext">提交</cube-button>
    </div>
  </div>
</template>

<script>
import SecurityCode from '@/components/SecurityCode.vue'
export default {
  name: 'Authentication',
  components: {
    SecurityCode
  },
  data () {
    return {
      id: this.$route.params.id,
      type: this.$route.query.type ? this.$route.query.type : 'view',
      phone: {
        show: '',
        key: ''
      },
      value: '',
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    /**
     * 验证号码
     */
    goNext () {
      console.log(this.value)
      if(! this.value){
        this.$createToast({
          txt: '请输入预留手机号码中间4位',
          type: 'warn'
        }).show()
        return
      }else if(this.value != this.phone.key){
        this.$createToast({
          txt: '验证失败，请核对手机号码',
          type: 'warn'
        }).show()
        return
      }
      this.$router.replace('/apply/dataText/' + this.id + '?type=' + this.type)
    },
    getInfo () {
      const vm = this;
      if(! this.id){
        this.$createToast({
          txt: '无合同信息',
          type: 'error'
        }).show()
        return
      }
      let postData = {
        policyId: this.id
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/policy/contact", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            let phone = response.data.body.phone
            
            vm.phone = {
              show: phone.substring(0, 3) + '****' + phone.substring(7, 11),
              key: phone.substring(3, 7)
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.au-cont{
  padding-top: 140px;
  text-align: center;
  padding-bottom: 200px;
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
}
.demo{
  display: block;
  width: 200px;
  margin: 0 auto;
}
.au-name{
  font-size: 36px;
  color: #208170;
  line-height: 36px;
  margin: 30px 0 120px 0;
}
.au-title{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  margin-bottom: 45px;
}
.btns{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 40px 0 63px 0;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #208170;
    border-radius: 50px;
    margin: 0 auto;
  }
}

</style>